<template>
  <div>
    <a-card :bordered="false" style="margin-bottom: 24px">
      <a-row :gutter="[24, 0]">
        <a-form-model class="ant-advanced-search-form">
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-model-item label="输入查询：">
              <a-input-search v-model="query.code" placeholder="请输入设备码" style="width: 100%" @search="initData" />
            </a-form-model-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-item label="录入时间：">
              <a-date-picker
                v-model="query.startTimestamp"
                valueFormat="YYYY-MM-DD"
                style="flex: 1"
                @change="initData"
              />
              <span style="margin: 0 20px">至</span>
              <a-date-picker v-model="query.endTimestamp" valueFormat="YYYY-MM-DD" style="flex: 1" @change="initData" />
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="12" :sm="24">
            <a-form-model-item label="所属机构：">
              <a-input-search
                v-model="query.mechanismName"
                placeholder="请输入机构"
                style="width: 100%"
                @search="initData"
              />
            </a-form-model-item>
          </a-col>
        </a-form-model>
      </a-row>
    </a-card>
    <a-card :body-style="{ padding: 0 }">
      <div slot="title">
        <span>已录入设备</span>
      </div>
      <div slot="extra">
        <a-button v-if="$auth('equipment_add')" type="primary" shape="round" @click="onAdd">
          <a-icon type="plus" />新增设备
        </a-button>
      </div>
      <main-table ref="table" v-if="$auth('equipment_list')"></main-table>
    </a-card>
    <Model ref="model" />
  </div>
</template>

<script>
import MainTable from './components/Table'
import Model from './components/Model'
export default {
  name: 'DeviceList',
  data() {
    return {
      query: { code: '', startTimestamp: '', endTimestamp: '', mechanismName: '' },
    }
  },
  components: {
    MainTable,
    Model,
  },
  activated() {
    if (this.ready) {
      this.initData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
    this.initData()
  },
  methods: {
    initData() {
      this.$refs.table.initData(this.query)
    },
    onAdd() {
      const {
        $refs: { model },
        $nextTick,
        initData,
      } = this
      $nextTick(() =>
        model.showModal((e) => {
          initData()
        })
      )
    },
  },
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
