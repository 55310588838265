<template>
  <a-modal
    centered
    bordered
    v-model="visible"
    :width="450"
    title="设备录入"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :ok-button-props="{ props: { loading, disabled: form.code.length === 0 } }"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-card :bordered="false" :body-style="{ padding: '30px 20px 10px 20px' }">
      <a-form-model ref="form" :model="form" :labelCol="{ span: 5 }" :wrapperCol="{ span: 15 }">
        <a-form-model-item label="设备码：" prop="code" :rules="checkRule">
          <a-input v-model.trim="form.code" :maxLength="20" />
        </a-form-model-item>
      </a-form-model>
    </a-card>
  </a-modal>
</template>

<script>
import { cloneDeep } from 'lodash'
import * as deviceApi from '@/api/device'
export default {
  data() {
    const Validator = (rule, value, callback) => {
      const idcardReg = /^[0-9a-zA-Z]*$/
      if (!idcardReg.test(value)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('只能包含字母数字（不区分大小写）')
      }
      callback()
    }
    return {
      checkRule: [
        { validator: Validator, trigger: ['change', 'blur'] },
        {
          required: true,
          max: 20,
          min: 20,
          message: '请输入',
          trigger: ['change', 'blur']
        }
      ],
      visible: false,
      loading: false,
      form: {
        code: ''
      }
    }
  },
  methods: {
    showModal(callback) {
      this.visible = true
      this.callback = callback
    },
    validate() {
      const {
        $refs: { form }
      } = this
      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
    formatForm() {
      try {
        const ret = cloneDeep(this.dataSource)
        return { ...ret }
      } catch {}
    },
    handleOk() {
      const { callback } = this
      const { $notification, validate } = this
      validate().then(valid => {
        if (valid) {
          this.loading = true
          try {
            deviceApi
              .create({ ...cloneDeep(this.form) })
              .then(res => {
                const { code } = res
                if (code === 0) {
                  $notification['success']({ message: '操作成功' })
                  callback()
                  this.callback = null
                  this.visible = false
                } else {
                  $notification['error']({ message: '提示', description: '该设备已存在，请查验后重新输入！' })
                }
              })
              .finally(() => {
                this.loading = false
                this.form.code = ''
              })
          } catch {
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    handleCancel() {
      this.form.code = ''
    }
  }
}
</script>

<style lang="less" scoped></style>
