<template>
  <a-table rowKey="id" :data-source="list" :pagination="pagination" :loading="list_loading" @change="handleTableChange">
    <a-table-column key="operationName" title="操作人" align="center" data-index="operationName" width="120px" />
    <a-table-column key="account" title="账号" align="center" data-index="account" width="120px" />
    <a-table-column key="mechanismName" title="所属机构" align="center" data-index="mechanismName" width="120px" />
    <a-table-column key="code" title="设备编码" align="center" data-index="code" width="120px" />
    <a-table-column key="createTimestamp" title="录入时间" align="center" data-index="createTimestamp" width="120px" />
  </a-table>
</template>

<script>
import * as deviceApi from '@/api/device'
export default {
  data() {
    return {
      list_loading: false,
      list: [],
      query: {},
      pagination: {}
    }
  },
  mounted() {},
  methods: {
    initData(query = {}) {
      this.query = query
      this.pagination = this.initPagination()
      return this.getData()
    },
    getData() {
      this.list_loading = true
      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this
      return deviceApi
        .list({ ...query, pageIndex, pageSize })
        .then(res => {
          const { list, totalItems: total } = res.data
          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.list_loading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: total => {
          const element = this.$createElement
          const {
            pagination: { pageSize }
          } = this
          return element('div', {}, [
            element('span', '共 '),
            element('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            element('span', ' 页'),
            element('span', ' / '),
            element('span', { class: 'primary-color' }, total),
            element('span', ' 条数据')
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100']
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      getData()
    }
  }
}
</script>

<style lang="less" scoped></style>
