import request from '@/utils/request'
const deviceApi = {
  list: '/equipment',
  create: '/equipment'
}
export function create(parameter) {
  return request({
    url: deviceApi.create,
    method: 'post',
    data: parameter
  })
}
export function list(parameter) {
  return request({
    url: deviceApi.list,
    method: 'get',
    params: parameter
  })
}
